@import "../_settings.scss";

.price {
    line-height: 1;
    display: inline-block;
}

.price-current,
.price-old,
.price-discount,
.price-new {
    display: block;
}

.price-new {
    margin-bottom: 2px;
}

.price-current {
}

.price-old {

    .price-number, .price-currency {
        text-decoration: line-through;
    }
}

.price-discount {
    color: #e20a0a;
}

.price-unknown {
    white-space: normal;
}

.price-currency{
    white-space:pre;
}

.price-number, .price-currency, .price-discount-abs, .price-discount-percent, .price-new-discount {
    display: inline-block;
}

.price-discount-percent-wrap {
    white-space: nowrap;
    display: inline-block;
}

.price-text-instead {
    white-space: normal;
    text-align: center;
    display: block;
}
