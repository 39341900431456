$sizeShadownHover: 10px;

.connector {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 500;

    &__list {
        display: none;
        padding: 0;
        margin: 0;
        list-style: none;
        overflow: visible;
        flex-wrap: wrap;
        flex-direction: column;
        max-height: calc(100vh - 80px);
    }

    &__list-item {
        padding: 0;
        margin: 20px 5px 0;
        position: relative;
    }

    &__list-item:hover &__popup {
        display: block;
    }

    &__item,
    &__trigger {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 0;
    }

    &__item--custom {
        background-color: #ababab;
    }

    &__trigger {
        position: relative;
        margin: 20px 5px 0;
    }

    &__trigger-el {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__trigger-close {
        display: none;
    }

    &__state {
        position: absolute;
        height: 0;
        width: 0;
        overflow: hidden;
        clip: rect(0,0,0,0);
    }

    &__state:checked ~ &__trigger &__trigger-open {
        display: none;
    }

    &__state:checked ~ &__trigger &__trigger-close {
        display: block;
    }

    &__state:checked ~ &__list {
        display: flex;
    }

    &__trigger, &__item--chat {
        background-color: #2d9ced;

        &:hover {
            box-shadow: 0 0 $sizeShadownHover 0 #2d9ced;
        }
    }

    &__connector__trigger-el {
        position: absolute;
    }

    &__item--vkontakte {
        background-color: #597da0;

        &:hover {
            box-shadow: 0 0 $sizeShadownHover 0 #597da0;
        }
    }

    &__item--facebook {
        background-color: #0084FF;

        &:hover {
            box-shadow: 0 0 $sizeShadownHover 0 #0084FF;
        }
    }

    &__item--callback {
        background-color: #055a98;

        &:hover {
            box-shadow: 0 0 $sizeShadownHover 0 #055a98;
        }
    }

    &__item--whatsapp {
        background-color: #26d367;

        &:hover {
            box-shadow: 0 0 $sizeShadownHover 0 #26d367;
        }
    }

    &__item--viber {
        background-color: #7c529e;

        &:hover {
            box-shadow: 0 0 $sizeShadownHover 0 #7c529e;
        }
    }

    &__item--telegram {
        background-color: #2da5e1;

        &:hover {
            box-shadow: 0 0 $sizeShadownHover 0 #2da5e1;
        }
    }

    &__item--odnoklassniki {
        background-color: #ef7d01;

        &:hover {
            box-shadow: 0 0 $sizeShadownHover 0 #ef7d01;
        }
    }

    &__popup {
        background: #fff;
        border-radius: 3px;
        position: absolute;
        right: 100%;
        margin-right: 10px;
        padding: 5px 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
        text-align: center;
        color: #4b4f58;
    }

    &__ip-telephony {
    }

    &__ip-telephony--compact {
        .connector__ip-telephony-form {
            display: none;
        }
    }

    &__ip-telephony--full ~ .connector__popup {
        display: none !important;
    }

    &__ip-telephony-form {
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        background-color: #055a98;
        display: table;
        border-radius: .3125rem;
        font-size: .875rem;
        padding: 5px;
        max-width: calc(100vw - 10px);
        box-sizing: border-box;
        width: 450px;
        margin-right: 10px;
    }

    &__ip-telephony-cell {
        display: table-cell;
        vertical-align: middle;
        padding: 5px;
    }

    &__input {
        font-size: 0.75rem;
        padding: 0.5625rem;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px #e2e3e4 solid;
        vertical-align: middle;
        width: 100%;
        appearance: none;
        box-shadow: none;
        height: auto;
        line-height: normal;
    }

    &__button {
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        border: 0;
        text-align: center;
        border-radius: 0.3125rem;
        line-height: 1.3;
        box-sizing: border-box;
        color: #fff;
        background: #e20000;
        cursor: pointer;

        &--small {
            font-weight: bold;
            font-size: 0.75rem;
            padding: 0.625rem 0.625rem 0.625rem;
        }

        &:hover {
            color: #fff;
            background: #fc0000;
            text-decoration: none;
        }

        &:active {
            color: #fff;
            background: #c90000;
            text-decoration: none;
        }
    }

    a.connector__item:hover {
        color: #fff;
    }

    &__ip-telephony-input {
        color: #4b4f58;
    }

    &__ip-telephony-close {
        position: absolute;
        bottom: 100%;
        left: 100%;
        font-size: 20px;
        line-height: 1;
        font-family: Arial;
        background-color: transparent;
        padding: 0;
        border: 0;
        color: #434142;
        cursor: pointer;
    }

    &__ip-telephony-copyright {
        position: absolute;
        top: 100%;
        font-size: 12px;
        right: 0;
    }
}

@media (min-height: 641px) {
    .connector__item,
    .connector__trigger {
        width: 56px;
        height: 56px;
    }
}

@media (max-width: 321px) {
    .connector__item,
    .connector__trigger {
        width: 36px;
        height: 36px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}


/*@media (min-width: 48em){
    .connector__popup{
        display: none;
    }
}*/