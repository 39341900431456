.mfp-custom-inline {
    .mfp-content {
        background-color: #fff;
        max-width: 75%;
        max-height: 75%;
        padding: 15px;
        box-sizing: border-box;
    }
}
.mfp-iframe-scaler iframe{
    box-shadow: none; //https://github.com/dimsemenov/Magnific-Popup/issues/1062
}